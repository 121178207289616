/**
 * laydate style
 */
 

html #layuicss-laydate{display: none; position: absolute; width: 1989px;}

/* 初始化 */
.layui-laydate *{margin: 0; padding: 0;}

/* 主体结构 */
.layui-laydate, .layui-laydate *{box-sizing: border-box;}
.layui-laydate{position: absolute; z-index: 99999999; margin: 5px 0; border-radius: 2px; font-size: 14px; line-height: normal; -webkit-animation-duration: 0.2s; animation-duration: 0.2s; -webkit-animation-fill-mode: both; animation-fill-mode: both;}
.layui-laydate-main{width: 272px;}
.layui-laydate-header *,
.layui-laydate-content td,
.layui-laydate-list li{transition-duration: .3s; -webkit-transition-duration: .3s;}
.layui-laydate-shade{top: 0; left: 0; width: 100%; height: 100%; _height: expression(document.body.offsetHeight+"px"); position: fixed; _position: absolute; pointer-events: auto;}

/* 微微往下滑入 */
@keyframes laydate-downbit {
  0% {opacity: 0.3; transform: translate3d(0, -5px, 0);}
  100% {opacity: 1; transform: translate3d(0, 0, 0);}
}

.layui-laydate{animation-name: laydate-downbit;}
.layui-laydate-static{ position: relative; z-index: 0; display: inline-block; margin: 0; -webkit-animation: none; animation: none;}

/* 展开年月列表时 */
.laydate-ym-show .laydate-prev-m,
.laydate-ym-show .laydate-next-m{display: none !important;}
.laydate-ym-show .laydate-prev-y,
.laydate-ym-show .laydate-next-y{display: inline-block !important;}
.laydate-ym-show .laydate-set-ym span[lay-type="month"]{display: none !important;}

/* 展开时间列表时 */
.laydate-time-show .layui-laydate-header .layui-icon,
.laydate-time-show .laydate-set-ym span[lay-type="year"],
.laydate-time-show .laydate-set-ym span[lay-type="month"]{display: none !important;}

/* 头部结构 */
.layui-laydate-header{position: relative; line-height:30px; padding: 10px 70px 5px;}
.layui-laydate-header *{display: inline-block; vertical-align: bottom;}
.layui-laydate-header i{position: absolute; top: 10px; padding: 0 5px; color: #999; font-size: 18px; cursor: pointer;}
.layui-laydate-header i.laydate-prev-y{left: 15px;}
.layui-laydate-header i.laydate-prev-m{left: 45px;}
.layui-laydate-header i.laydate-next-y{right: 15px;}
.layui-laydate-header i.laydate-next-m{right: 45px;}
.laydate-set-ym{width: 100%; text-align: center; box-sizing: border-box; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
.laydate-set-ym span{padding: 0 10px; cursor: pointer;}
.laydate-time-text{cursor: default !important;}

/* 主体结构 */
.layui-laydate-content{position: relative; padding: 10px; -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;}
.layui-laydate-content table{border-collapse: collapse; border-spacing: 0;}
.layui-laydate-content th,
.layui-laydate-content td{width: 36px; height: 30px; padding: 0; text-align: center;}
.layui-laydate-content th{font-weight: 400;}
.layui-laydate-content td{position: relative; cursor: pointer;}
.laydate-day-mark{position: absolute; left: 0; top: 0; width: 100%; line-height: 30px; font-size: 12px; overflow: hidden;}
.laydate-day-mark::after{position: absolute; content:''; right: 2px; top: 2px; width: 5px; height: 5px; border-radius: 50%;}
.laydate-day-holidays:before{position: absolute; left: 0; top: 0; font-size: 12px; transform: scale(.7);}
.laydate-day-holidays:before{content:'\4F11'; color: #FF5722;}
.laydate-day-holidays[type="workdays"]:before{content:'\73ED'; color: inherit;}
.layui-laydate .layui-this .laydate-day-holidays:before{color: #fff;}

/* 底部结构 */
.layui-laydate-footer{position: relative; height: 46px; line-height: 26px; padding: 10px;}
.layui-laydate-footer span{display: inline-block;  vertical-align: top; height: 26px; line-height: 24px; padding: 0 10px; border: 1px solid #C9C9C9; border-radius: 2px; background-color: #fff; font-size: 12px; cursor: pointer; white-space: nowrap; transition: all .3s;}
.layui-laydate-footer span:hover{color: #16b777;}
.layui-laydate-footer span.layui-laydate-preview{cursor: default; border-color: transparent !important;}
.layui-laydate-footer span.layui-laydate-preview:hover{color: #777;}
.layui-laydate-footer span:first-child.layui-laydate-preview{padding-left: 0;}
.laydate-footer-btns {position: absolute; right: 10px; top: 10px; }
.laydate-footer-btns span{margin: 0 0 0 -1px; border-radius: 0px; }
.laydate-footer-btns span:first-child { border-radius: 2px 0px 0px 2px;}
.laydate-footer-btns span:last-child { border-radius: 0px 2px 2px 0px;}

/* 快捷栏 */
.layui-laydate-shortcut{width: 80px; padding: 6px 0; display: inline-block;vertical-align: top; overflow: auto; max-height: 276px; text-align: center;}
.layui-laydate-shortcut+.layui-laydate-main{display: inline-block;border-left: 1px solid #e2e2e2;}
.layui-laydate-shortcut>li{padding: 5px 8px; cursor: pointer; line-height: 18px;}

/* 年月列表 */
.layui-laydate .layui-laydate-list{position: absolute; left: 0; top: 0; width: 100%; height: 100%; padding: 10px; box-sizing: border-box; background-color: #fff;}
.layui-laydate .layui-laydate-list>li{position: relative; display: inline-block; width: 33.3%; height: 36px; line-height: 36px; margin: 3px 0; vertical-align: middle; text-align: center; cursor: pointer; list-style: none;}
.layui-laydate .laydate-month-list>li{width: 25%; margin: 17px 0;}
.laydate-time-list{display: table;}
.layui-laydate .laydate-time-list>li{display: table-cell; height: 100%; margin: 0; line-height: normal; cursor: default;}
.layui-laydate .laydate-time-list p{position: relative; top: -4px; margin: 0; line-height: 29px;}
.layui-laydate .laydate-time-list ol{height: 181px; overflow: hidden;}
.layui-laydate .laydate-time-list>li:hover ol{overflow-y: auto;}
.layui-laydate .laydate-time-list ol li{width: 130%; padding-left: 33px; height: 30px; line-height: 30px; text-align: left; cursor: pointer;}
.layui-laydate .laydate-time-list-hide-1 ol li{padding-left: 53px;}
.layui-laydate .laydate-time-list-hide-2 ol li{padding-left: 117px;}

/* 提示 */
.layui-laydate-hint{position: absolute; top: 115px; left: 50%; width: 250px; margin-left: -125px; line-height: 20px; padding: 15px; text-align: center; font-size: 12px; color: #FF5722;}


/* 双日历 */
.layui-laydate-range{width: 546px;}
.layui-laydate-range .layui-laydate-main{display: inline-block; vertical-align: middle;max-width: 50%;}
.layui-laydate-range .laydate-main-list-1 .layui-laydate-header,
.layui-laydate-range .laydate-main-list-1 .layui-laydate-content{border-left: 1px solid #e2e2e2;}
.layui-laydate-range.layui-laydate-linkage .laydate-main-list-0 .laydate-next-m, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-0 .laydate-next-y,
.layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .laydate-prev-m, .layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .laydate-prev-y{display: none;}
.layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .layui-laydate-header,
.layui-laydate-range.layui-laydate-linkage .laydate-main-list-1 .layui-laydate-content{border-left-style: dashed;}


/* 默认简约主题 */
.layui-laydate, .layui-laydate-hint{border: 1px solid #d2d2d2; box-shadow: 0 2px 4px rgba(0,0,0,.12); background-color: #fff; color: #777;}
.layui-laydate-header{border-bottom: 1px solid #e2e2e2;}
.layui-laydate-header i:hover,
.layui-laydate-header span:hover{color: #16b777;}
.layui-laydate-content{border-top: none 0; border-bottom: none 0;}
.layui-laydate-content th{color: #333;}
.layui-laydate-content td{color: #777;}
.layui-laydate-content td.laydate-day-now{color: #16b777;}
.layui-laydate-content td.laydate-day-now:after{content: ''; position: absolute; width: 100%; height: 30px; left: 0; top: 0; border: 1px solid #16b777; box-sizing: border-box;}
.layui-laydate-linkage .layui-laydate-content td.laydate-selected>div{background-color: #cffae9; transition: all .3s;}
.layui-laydate-linkage .laydate-selected:hover>div{background-color: #cffae9 !important;}
.layui-laydate-content td:hover:after,
.layui-laydate-content td.laydate-selected:after{content: none;}
.layui-laydate-content td>div:hover,
.layui-laydate-list li:hover,
.layui-laydate-shortcut>li:hover{background-color: #eee; color: #333; transition: all .3s;}
.laydate-time-list li ol{margin: 0; padding: 0; border: 1px solid #e2e2e2; border-left-width: 0;}
.laydate-time-list li:first-child ol{border-left-width: 1px;}
.laydate-time-list>li:hover{background: none;}
.layui-laydate-content .laydate-day-prev,
.layui-laydate-content .laydate-day-next{color: #d2d2d2;}
.layui-laydate-linkage .laydate-selected.laydate-day-prev>div,
.layui-laydate-linkage .laydate-selected.laydate-day-next>div{background: none !important;}
.layui-laydate-footer{border-top: 1px solid #e2e2e2;}
.layui-laydate-hint{color: #FF5722;}
.laydate-day-mark::after{background-color: #16b777;}
.layui-laydate-content td.layui-this .laydate-day-mark::after{display: none;}
.layui-laydate-footer span[lay-type="date"]{color: #16b777;}
.layui-laydate .layui-this,.layui-laydate .layui-this>div{background-color: #16b777 !important; color: #fff !important;}
.layui-laydate .laydate-disabled,
.layui-laydate .laydate-disabled:hover{background:none !important; color: #d2d2d2 !important; cursor: not-allowed !important; -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;}
.layui-laydate .layui-this.laydate-disabled,.layui-laydate .layui-this.laydate-disabled>div{background-color: #eee !important}
.layui-laydate-content td>div{padding: 7px 0; height: 100%;}

/* 墨绿/自定义背景色主题 */
.laydate-theme-molv{border: none;}
.laydate-theme-molv.layui-laydate-range{width: 548px}
.laydate-theme-molv .layui-laydate-main{width: 274px;}
.laydate-theme-molv .layui-laydate-header{border: none; background-color: #16baaa;}
.laydate-theme-molv .layui-laydate-header i,
.laydate-theme-molv .layui-laydate-header span{color: #f6f6f6;}
.laydate-theme-molv .layui-laydate-header i:hover,
.laydate-theme-molv .layui-laydate-header span:hover{color: #fff;}
.laydate-theme-molv .layui-laydate-content{border: 1px solid #e2e2e2; border-top: none; border-bottom: none;}
.laydate-theme-molv .laydate-main-list-1 .layui-laydate-content{border-left: none;}
.laydate-theme-molv .layui-this, .laydate-theme-molv .layui-this>div{background-color: #16baaa !important;}
.laydate-theme-molv .layui-laydate-footer{border: 1px solid #e2e2e2;}

/* 格子主题 */
.laydate-theme-grid .layui-laydate-content td,
.laydate-theme-grid .layui-laydate-content thead,
.laydate-theme-grid .laydate-year-list>li,
.laydate-theme-grid .laydate-month-list>li{border: 1px solid #e2e2e2;}
.layui-laydate-linkage.laydate-theme-grid .laydate-selected,
.layui-laydate-linkage.laydate-theme-grid .laydate-selected:hover{background-color: #f2f2f2 !important; color: #16baaa !important;}
.layui-laydate-linkage.laydate-theme-grid .laydate-selected.laydate-day-prev,
.layui-laydate-linkage.laydate-theme-grid .laydate-selected.laydate-day-next{color: #d2d2d2 !important;}
.laydate-theme-grid .laydate-year-list,
.laydate-theme-grid .laydate-month-list{margin: 1px 0 0 1px;}
.laydate-theme-grid .laydate-year-list>li,
.laydate-theme-grid .laydate-month-list>li{margin: 0 -1px -1px 0;}
.laydate-theme-grid .laydate-year-list>li{height: 43px; line-height: 43px;}
.laydate-theme-grid .laydate-month-list>li{height: 71px; line-height: 71px;}
.laydate-theme-grid .layui-laydate-content td>div{height: 29px;margin-top: -1px;}

/* 圆圈高亮主题 */
.laydate-theme-circle .layui-laydate-content td>div,
.laydate-theme-circle .layui-laydate-content td.layui-this>div{width: 28px;height: 28px;line-height: 28px;border-radius: 14px;margin: 0 4px;padding: 0;}
.layui-laydate.laydate-theme-circle .layui-laydate-content table td.layui-this{background-color: transparent !important;}
.laydate-theme-grid.laydate-theme-circle .layui-laydate-content td>div{margin: 0 3.5px;}

/* 全面板 */
.laydate-theme-fullpanel .layui-laydate-main {width: 526px;}
.laydate-theme-fullpanel .layui-laydate-list {width: 252px;left: 272px;}
.laydate-theme-fullpanel .laydate-set-ym span {display: none;}
.laydate-theme-fullpanel .laydate-time-show .layui-laydate-header .layui-icon,
.laydate-theme-fullpanel .laydate-time-show .laydate-set-ym span[lay-type="year"],
.laydate-theme-fullpanel .laydate-time-show .laydate-set-ym span[lay-type="month"] {display: inline-block !important;}
.laydate-theme-fullpanel .laydate-btns-time{display: none;}
.laydate-theme-fullpanel .laydate-time-list-hide-1 ol li{padding-left: 49px;}
.laydate-theme-fullpanel .laydate-time-list-hide-2 ol li{padding-left: 107px;}
